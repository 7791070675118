@use 'vars' as *;
@use 'typography' as *;
@use 'direction' as *;

@mixin layout-box {
  background: var(--ln-color-background);
  box-shadow: 0 20px 60px var(--ln-color-text-gray-20);
  border-radius: 12px;
}

@mixin layout-block {
  margin-top: 30px;
  display: block;

  @media screen and (max-width: $laptop) {
    margin-top: 16px;
  }
}

.default-layout {
  $root: &;

  &__container {
    display: flex;

    &:not(.list) {
      margin-top: -73px;
    }

    &.list {
      margin-top: 0;
      width: 100%;
    }

    @media screen and (max-width: $laptop) {
      &.reverse-laptop {
        flex-direction: column-reverse;

        .default-layout__main {
          margin-top: var(--mrg-row);
        }

        .default-layout__side {
          margin-top: 30px;
        }
      }

      &:not(.reverse-laptop) {
        flex-direction: column;

        .default-layout__side {
          margin-top: var(--mrg-row);
        }
      }

      &.list {
        margin-top: -15px;
      }

      &:not(.list) {
        margin-top: -46px;
      }
    }
  }

  &__main {
    &:not(.list) {
      flex: 1 1 auto;
      max-width: calc(100% - 380px);

      @media screen and (max-width: $laptop) {
        max-width: 100%;
        margin-top: 30px;
      }
    }

    &.list {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      height: 100%;

      @media screen and (max-width: $laptop) {
        flex-direction: column;
      }
    }
  }

  &__pagination {
    max-width: calc(100% - 380px);
    margin-top: 24px;

    @media screen and (max-width: $laptop) {
      margin-top: 32px;
      max-width: none;
    }
  }

  &__list {
    display: block;
    margin-top: -115px;

    @media screen and (max-width: $laptop) {
      margin-top: -63px;
    }
  }

  &__nodatafound {
    @include title-text;
    @include layout-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 200px;
    padding: var(--mrg-row) var(--mrg-col);

    @media screen and (max-width: $laptop) {
      margin-top: 32px;
    }
  }

  &__side {
    @include margin-start(30px, false);

    max-width: 350px;
    width: 100%;
    flex: none;
    border-radius: 4px;

    @media screen and (max-width: $laptop) {
      @include margin-start(0, false);

      max-width: none;
      width: auto;
      transition: all 0.3s;
    }

    &-sticky {
      @media screen and (max-width: $laptop) {
        top: -40px;
        position: sticky;
        z-index: 4;
      }

      &-show {
        @media screen and (max-width: $laptop) {
          top: $header-height-thin;
          background: var(--ln-color-background);
        }
      }
    }
  }

  &__top {
    margin-top: -42px;
    margin-bottom: 5px;
    position: relative;
    z-index: 5;

    &.list {
      max-width: calc(100% - 380px);

      @media screen and (max-width: $laptop) {
        max-width: none;
        margin-top: -50px;
      }
    }

    &-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      --margin-sort: -#{$container-padding};

      @media screen and (max-width: $mobile) {
        --margin-sort: -#{$container-padding-mobile};
      }

      & > ln-list-sort {
        @include margin-end(var(--margin-sort), false);
      }
    }

    &-text {
      color: var(--ln-color-background);

      span {
        position: relative;
        cursor: pointer;
      }
    }
  }
}

.layout-box {
  @include layout-box;
}

.layout-block {
  @include layout-block;
}

.layout-element {
  @include layout-box;
  @include layout-block;
}
