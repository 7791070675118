import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  forwardRef
} from '@angular/core';
import { ActivatedRoute, ChildActivationEnd, NavigationEnd, Router } from '@angular/router';
import { ArrowDirection } from '@app/shared/controls/button/button.types';
import { SharedModule } from '@app/shared/shared.module';
import { buffer, filter, map, Observable, ReplaySubject, takeUntil } from 'rxjs';
import { RouteData } from '@app/shared/types';
import { AppSettingsService } from '@app/shared/services';
import { MenuWrapperComponent } from '../menu-wrapper/menu-wrapper.component';
import { NavigationService } from '@shared/services/navigation.service';
import { ButtonArrowComponent, ButtonComponent } from '@shared/controls';

@Component({
  imports: [SharedModule, ButtonComponent, ButtonArrowComponent, forwardRef(() => MenuWrapperComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ln-layout-default',
  templateUrl: './layout-default.component.html',
  styleUrls: ['./layout-default.component.scss']
})
export class LayoutDefaultComponent implements OnInit, OnDestroy, AfterViewInit {
  public ArrowDirection = ArrowDirection;
  public destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public isBigHeader: boolean | undefined = false;
  public dataRoute?: RouteData;
  public prevUrl?: string;
  public titleValue?: Record<string, unknown>;
  public titleText$?: Observable<string | undefined>;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private location: Location,
    protected settings: AppSettingsService,
    private cdr: ChangeDetectorRef,
    private navigation: NavigationService
  ) {}

  ngOnInit() {
    this.settings.setLayoutTitleValue('');
    const routeEndEvent$ = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));
    this.isBigHeader = this.route.snapshot.data['isBigHeader'];
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((e) => e instanceof ChildActivationEnd && e.snapshot.component === this.route.component),
        buffer(routeEndEvent$),
        map(([ev]) => (ev as ChildActivationEnd)?.snapshot?.firstChild?.data)
      )
      .subscribe((childData) => {
        this.isBigHeader = childData?.['isBigHeader'];
        this.cdr.detectChanges();
      });

    this.route.data.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.dataRoute = data;

      if (typeof this.dataRoute?.['titleValue'] === 'function') {
        this.titleValue = this.dataRoute?.['titleValue'](this);
      }
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.titleText$ = this.settings.layoutTitleValue$.pipe(filter((value) => !!value));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onBack() {
    if (!this.navigation.back()) {
      const getPath = (data: RouteData) =>
        Array.isArray(data.breadcrumbs) ? data.breadcrumbs[data.breadcrumbs.length - 1] : null;
      let path = getPath(this.route.snapshot.data) || getPath(this.route.snapshot.children[0]?.data);
      if (path) this.router.navigateByUrl(path, { replaceUrl: true });
      else this.location.back();
    }
  }
}
