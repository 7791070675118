import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { ReplaySubject, takeUntil } from 'rxjs';
import { MenuWrapperComponent } from '../menu-wrapper/menu-wrapper.component';

@Component({
  imports: [SharedModule, MenuWrapperComponent],
  selector: 'ln-layout-empty',
  templateUrl: './layout-empty.component.html',
  styleUrls: ['./layout-empty.component.scss']
})
export class LayoutEmptyComponent implements OnInit, OnDestroy {
  public isFullScreen: boolean | undefined = false;
  public destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(protected route: ActivatedRoute) {}

  ngOnInit() {
    this.isFullScreen = this.route.snapshot.data['isBigHeader'];

    this.route.data.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.isFullScreen = data['isFullScreen'];
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
