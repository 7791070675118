<div
  class="menu-mobile"
  [class]="{ 'search-opened': searchOpened }"
  ln-click-out
  (clickOut)="closeForm.emit()"
  ln-swipe
  (next)="closeSwipe(true)"
  (previous)="closeSwipe(false)"
>
  <div class="menu-mobile__head">
    <div class="menu-mobile__head-wrapper">
      <div class="menu-mobile__head-common" ln-click-out (clickOut)="isShowSignIn = false">
        @if ((authService.isAuthorized$ | async) === true) {
          <div class="menu-mobile__head-profile">
            <ln-icon name="user" width="24px" height="32px" class="menu-mobile__head-profile-ava"></ln-icon>
            <div class="menu-mobile__head-profile-info">
              <div class="menu-mobile__head-profile-name">{{ userService.user?.name?.first }}</div>
              <a class="menu-mobile__head-profile-view" [routerLink]="'/client/profile' | localize">{{
                'layouts.header.menuMobile.viewProfile' | translate
              }}</a>
            </div>
          </div>
        } @else {
          <button ln-button class="outlined orange" [small]="true" (click)="isShowSignIn = !isShowSignIn">
            {{ 'layouts.header.menuMobile.signIn' | translate }}
          </button>
          <div class="menu-mobile__sign-overlay" [class]="{ 'menu-mobile__sign-overlay-show': isShowSignIn }">
            @defer (on idle) {
              <ln-signin
                *ngIf="isShowSignIn !== null"
                (login)="isShowSignIn = false"
                (closed)="isShowSignIn = false"
                class="menu-mobile__sign"
                idPostfix="2"
              ></ln-signin>
            }
          </div>
        }
      </div>
      <div class="menu-mobile__head-search">
        <button ln-button-arrow [direction]="ArrowDirection.Left" class="text orange" (click)="onBack()">
          {{ 'global.buttons.back' | translate }}
        </button>
      </div>
    </div>
    <div class="menu-mobile__head-region" ln-click-out (clickOut)="isShowRegion = false">
      <div (click)="onRegion()" class="menu-mobile__head-region-selector">
        <span
          *ngIf="!disableCountries"
          class="menu-mobile__head-region-flag"
          class="fi fi-{{ (settings.country$ | async)?.shortCode | lowercase }}"
        ></span>
        {{ localize.parser.currentLang | uppercase }}
      </div>
      <div
        class="menu-mobile__head-region-overlay"
        [class]="{ 'menu-mobile__head-region-overlay-show': isShowRegion }"
        (click)="$event.stopPropagation()"
      >
        <ng-template ln-lazy-component #regionPanel="lazyDirective"></ng-template>
      </div>
    </div>
  </div>
  <div class="menu-mobile__search">
    @defer (when opened) {
      @if (breakpoints.mobile$ | async) {
        <ln-search-panel
          #searchPanel
          (closeForm)="hide()"
          (inputFocus)="onSearchShow()"
          [opened]="searchOpened"
          [forMobile]="true"
        ></ln-search-panel>
      }
    }
  </div>

  <div class="menu-mobile__items">
    <ln-menu-item (selected)="hide()" *ngFor="let item of menuItems" [item]="item"> </ln-menu-item>
    <hr />
    <ln-menu-item (selected)="hide()" *ngFor="let item of menuItems2" [item]="item"></ln-menu-item>
  </div>
</div>
