<ln-menu-wrapper>
  <div
    class="layout-default"
    [class]="{
      'layout-default__list': !!isBigHeader
    }"
  >
    <div class="layout-default__header">
      <div class="app-container">
        <div class="layout-default__header-wrapper">
          <div class="layout-default__header-title">
            <button
              ln-button-arrow
              [direction]="ArrowDirection.Left"
              class="text big"
              (click)="onBack()"
              [title]="'layouts.header.buttonBack' | translate"
            ></button>
            <h1 *ngIf="!dataRoute?.titleH2" class="h2">
              {{ (titleText$ | async) || dataRoute?.['title'] || '' | translate: titleValue }}
            </h1>
            <h2 *ngIf="dataRoute?.titleH2" class="h2">
              {{ (titleText$ | async) || dataRoute?.['title'] || '' | translate: titleValue }}
            </h2>
          </div>
          <a
            *ngIf="dataRoute?.['button']"
            ln-button
            [routerLink]="dataRoute?.['button']?.link || '' | localize"
            class="filled orange"
          >
            {{ dataRoute?.['button']?.label || '' | translate }}
          </a>
        </div>
      </div>
    </div>

    <div class="layout-default__body">
      <div class="app-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ln-menu-wrapper>
