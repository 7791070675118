import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { COMPANY_NAME, COPYRIGHT } from '@app/shared/const/settings';
import { AppSettingsService, BreakpointsService } from '@app/shared/services';
import { Sections } from '@app/shared/types';
import { getSectionLink, getServicesLinkWithCountry } from '@app/shared/utils';
import { BannerPopularQuestionsComponent } from '@comp/banners/popular-questions/popular-questions.component';
import { SECTIONS_PATH } from '@shared/const/sections';
import { ButtonArrowComponent, ButtonComponent } from '@shared/controls';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: '[ln-footer]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule, ButtonComponent, ButtonArrowComponent, BannerPopularQuestionsComponent]
})
export class FooterComponent {
  copyright = COPYRIGHT;
  getSectionLink = getSectionLink;
  getServicesLinkWithCountry = getServicesLinkWithCountry;
  Sections = Sections;

  protected showBanner = false;

  protected social = [
    {
      icon: 'fb',
      link: 'https://www.facebook.com/legaladviceme',
      title: 'Facebook ' + COMPANY_NAME
    },
    {
      icon: 'instagram',
      link: 'https://instagram.com/legaladviceme',
      title: 'Instagram ' + COMPANY_NAME
    },
    {
      icon: 'x',
      link: 'https://twitter.com/legaladviceme',
      title: 'X. ' + COMPANY_NAME
    },
    {
      icon: 'linkedin',
      link: 'https://linkedin.com/company/legal-advice-middle-east',
      title: 'Linkedin ' + COMPANY_NAME
    }
  ];

  get currentYear() {
    return new Date().getFullYear();
  }

  constructor(
    protected settings: AppSettingsService,
    private router: Router,
    protected breakpoints: BreakpointsService
  ) {
    Sections;
    const bannersPath = [
      SECTIONS_PATH[Sections.Questions],
      SECTIONS_PATH[Sections.SelfHelp],
      SECTIONS_PATH[Sections.Laws],
      SECTIONS_PATH[Sections.LegalDictionary],
      SECTIONS_PATH[Sections.LegalBlog]
    ];

    this.showBanner = new RegExp(`^\/(${bannersPath.join('|')})\\b`, 'i').test(this.router.url);
  }
}
