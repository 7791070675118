@use 'vars' as *;

.hide {
  display: none;
}

.show {
  display: block;
}

.#{'hide-'} {
  &mobile {
    @media screen and (min-width: calc($mobile + 1px)) {
      display: none !important;
    }
  }

  &tablet {
    @media screen and (min-width: calc($tablet + 1px)) {
      display: none !important;
    }
  }

  &laptop {
    @media screen and (min-width: calc($laptop + 1px)) {
      display: none !important;
    }
  }
}

.#{'show-'} {
  &mobile {
    @media screen and (min-width: calc($mobile + 1px)) {
      display: block !important;
    }
  }

  &tablet {
    @media screen and (min-width: calc($tablet + 1px)) {
      display: block !important;
    }
  }

  &laptop {
    @media screen and (min-width: calc($laptop + 1px)) {
      display: block !important;
    }
  }
}
