<header
  ln-header
  (toggleBurger)="toggleBurger()"
  (toggleChat)="toggleChat()"
  [menuMobileOpened]="menuMobileOpened"
  [chatOpened]="listOpened"
></header>
<ln-menu-mobile [opened]="menuMobileOpened" (closeForm)="closeMenu()"></ln-menu-mobile>
@defer (on idle) {
  <ln-lawyer-list
    [expanded]="listOpened"
    (closeForm)="closeChat()"
    *ngIf="breakpoints.mobile$ | async"
  ></ln-lawyer-list>
}
<main [class]="{ 'chat-opened': listOpened, 'menu-opened': menuMobileOpened }">
  <ng-content></ng-content>
</main>
<footer ln-footer [class]="{ 'chat-opened': listOpened, 'menu-opened': menuMobileOpened }"></footer>
