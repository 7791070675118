@use 'vars' as *;
@use 'typography' as *;
@use 'layouts' as *;
@use 'utils' as *;
@use 'direction' as *;

.footer {
  &__top {
    display: flex;
    padding: 24px 0;

    @media screen and (max-width: $laptop) {
      flex-direction: column-reverse;
      padding: 16px 0;
    }
  }

  &__nav {
    display: flex;
    flex: 1 1 auto;

    @media screen and (max-width: $tablet) {
      flex-wrap: wrap;
      margin: -$container-padding;
    }

    @media screen and (max-width: $mobile) {
      margin: -$container-padding-mobile;
    }

    &-box {
      flex: 1 1 1px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &:nth-child(3) {
        flex: 1 1 50px;
      }

      @media screen and (max-width: $tablet) {
        flex: 1 1 130px !important;
        margin: 15px;
      }

      a,
      span {
        @include common-text;

        color: var(--ln-color-primary-light);
        display: block;
        margin: 8px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:not(.footer__nav-thin) {
          font-weight: 500;
        }
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__dialog {
    background: var(--ln-color-background);
    border: 1px dashed var(--ln-color-border-gray);
    border-radius: 12px;
    padding: 24px;
    margin-left: 0;

    @media screen and (max-width: $laptop) {
      margin-bottom: 16px;
      padding: 15px;
    }

    &-question {
      @include button-text;

      text-transform: uppercase;
    }

    &-buttons {
      display: flex;
      justify-content: flex-start;

      a {
        &:first-child {
          @include margin-end(24px, false);
        }
      }

      &-icon {
        @include margin-start(10px, false);
      }
    }

    h5 {
      color: var(--ln-color-primary-light);
      font-weight: 500;
      margin: 0;
    }

    p {
      color: var(--ln-color-primary-light);
    }
  }

  &__bottom {
    border-top: 1px solid var(--ln-color-border-gray);
    padding: 22px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    color: var(--ln-color-primary-light);

    @media screen and (max-width: $mobile) {
      flex-direction: column-reverse;
      justify-content: center;
    }
  }

  &__social {
    display: flex;
    margin: 0 -12px;

    @media screen and (max-width: $mobile) {
      margin-bottom: 20px;
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 12px;

      img {
        display: block;
      }
    }
  }

  &__banner {
    margin-bottom: var(--mrg-row);
  }
}
