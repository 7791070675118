@use 'direction' as *;
@use 'vars' as *;
@use 'typography' as *;
@use 'utils' as *;
@use 'visibility' as *;

.header {
  --header-height: #{$header-height-normal};

  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--ln-color-header);
  border-bottom: 1px solid rgba(var(--ln-color-background-rgb), 0.2);
  transition:
    background 0.2s ease,
    height 0.3s ease;
  height: var(--header-height);

  @media screen and (max-width: $laptop) {
    transition:
      background 0.2s ease,
      border-color 0.4s ease,
      transform 0.4s ease-in-out;

    --header-height: #{$header-height-thin};
  }

  $header: &;

  &.burger-opened {
    pointer-events: none;

    @include aside(true, $menu-width);
  }

  &.chat-opened {
    pointer-events: none;

    @media screen and (max-width: $laptop) {
      @include aside(false, $lawyers-width-mobile);
    }
  }

  &::after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  &.sticky {
    --header-height: #{$header-height-thin};

    #{$header} {
      &__logo > img {
        width: 135px;
      }
    }
  }

  &__search {
    position: relative;
    display: flex;
    align-items: center;

    &.fade {
      box-shadow:
        0 0 0 100vw rgba(var(--ln-color-primary-rgb), 0.2),
        inset 0 0 0 100vw rgba(var(--ln-color-primary-rgb), 0.2);
    }

    &-overlay {
      max-height: calc(100vh - 23px);

      @include overlay(calc(100% - 35px), $offset-mobile);
    }
  }

  &__mobile {
    display: none;

    @media screen and (max-width: $laptop) {
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-logo {
      display: none;

      @media screen and (max-width: $laptop) {
        position: absolute;
        left: 50%;
        right: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          min-width: 130px;
          height: auto;
        }
      }
    }
  }

  &__burger,
  &__chat {
    width: 50px;
    height: $header-height-thin;
    color: var(--ln-color-text-gray);
    cursor: pointer;
    display: flex !important;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  &__desktop {
    @media screen and (max-width: $laptop) {
      display: none !important;
    }
  }

  &__logo {
    img {
      transition: all 0.3s;
      display: block;
      width: 185px;
      height: auto;
    }

    @media screen and (max-width: $laptop) {
      display: none;
    }
  }

  &__language {
    color: var(--ln-color-secondary) !important;
    font-weight: 900;
  }

  .app-container-full {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    @media screen and (max-width: $laptop) {
      padding: 0;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    @media screen and (max-width: $laptop) {
      flex: 1 1 auto;
    }
  }

  &__nav {
    display: flex;
    margin: 0 30px;
    height: 100%;

    @media screen and (max-width: $laptop) {
      display: none !important;
    }
  }

  &__link {
    @include button-text;

    --color-link: var(--ln-color-text-gray);
    color: var(--ln-color-text-gray);
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 24px;
    position: relative;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      color: var(--ln-color-background);
      text-decoration: none !important;
    }

    @media screen and (max-width: $desktop) {
      margin: 0 20px;
    }

    @media screen and (max-width: calc($laptop + 80px)) {
      margin: 0 15px;
    }

    @media screen and (max-width: $laptop) {
      height: auto;
    }

    &-active {
      --color-link: var(--ln-color-background);

      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: var(--ln-color-background);
        border-radius: 12px 12px 0 0;
      }
    }
  }

  &__sign {
    @include rtl-value(margin, 0 -15px 0 20px, 0 20px 0 -15px, false);

    &-button {
      height: 32px !important;

      &-text {
        transition: transform 0.3s;

        &.collapse {
          transform: scale(0);
        }
      }

      &.header__link-active {
        color: var(--ln-color-background) !important;
        transition: 0.1s;
      }
    }

    &-overlay {
      transform-origin: center !important;

      @include overlay(calc(100% + 5px), calc(50vw - (320px / 2)));
    }
  }

  &__region {
    position: relative;

    &-flag {
      width: 22px;
      height: 15px;
      display: block;
      border-radius: 3px;

      @include margin-end(8px, false);

      @media screen and (max-width: $laptop) {
        @include margin-end(1px, false);
      }
    }

    &-tip {
      @include label-text;

      width: 250px;
      padding: 24px;
      position: relative;
      background: var(--ln-color-background);
      border-radius: 12px;

      &-wrapper {
        visibility: hidden;
        margin-right: -15px;
        margin-left: auto;

        @include rtl(false) {
          margin-right: auto;
          margin-left: -15px;
        }

        &-show {
          visibility: visible;
        }
      }

      &-close {
        position: absolute;
        right: 8px;
        top: 8px;
        color: var(--ln-color-text-gray);

        &:hover {
          cursor: pointer;
        }
      }

      @media screen and (max-width: $laptop) {
        @include small-text;

        width: auto;
        padding: 16px;
        box-shadow: 0 0 0 1px var(--ln-color-text-gray-20) inset;
      }
    }

    &-tooltip {
      height: 40px;
      display: flex;
      align-items: center;
    }

    &-tip-overlay {
      padding-top: 13px;
      background: transparent;
      box-shadow: none;
      left: 4px;

      @include overlay(calc(100% - 20px));

      @media screen and (max-width: $laptop) {
        left: 4px !important;
        right: 4px !important;
      }

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: 38px;
        border-left: 22px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 15px solid var(--ln-color-background);

        @include rtl(false) {
          left: 38px;
          right: initial;
          border-left: 5px solid transparent;
          border-right: 22px solid transparent;
        }

        @media screen and (max-width: $laptop) {
          @include rtl-prop(right, left, 58px, false);
        }
      }
    }

    &-overlay {
      @include overlay(calc(100% - 15px), 22px);

      @media screen and (max-width: $laptop) {
        right: 0 !important;
        left: 0 !important;
      }
    }
  }

  &__loader {
    --width-line: 400px;

    @media screen and (min-width: $mobile) {
      --width-line: 1000px;
    }

    @media screen and (max-width: $laptop) {
      position: absolute;
      z-index: 2000;
      bottom: -1px;
      width: 100%;
      height: 3px;
      background-image:
        linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, var(--ln-color-header)),
        linear-gradient(90deg, transparent 0%, var(--ln-color-background-gray));
      background-size:
        cover,
        var(--width-line) 50px;
      background-position:
        top,
        0 0;
      text-indent: -999em;
      animation: loading 1s infinite linear;
    }
  }
}

@keyframes loading {
  0% {
    background-position:
      top,
      0 0;
  }
  100% {
    background-position:
      top,
      var(--width-line) 0;
  }
}
