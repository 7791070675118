import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  PLATFORM_ID,
  Inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  HostBinding
} from '@angular/core';
import { ArrowDirection } from '@app/shared/controls/button/button.types';
import { AppSettingsService, AuthService, BreakpointsService, UserService } from '@app/shared/services';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { MENU_ITEMS, MENU_ITEMS2 } from './menu-mobile.const';
import { GtagService } from '@app/shared/services/gtag.service';
import { SearchPanelComponent } from '@app/shared/components/search-panel/search-panel.component';
import { LazyComponentDirective } from '@app/shared/directives/lazy-component.directive';
import { ReplaySubject, merge, takeUntil } from 'rxjs';
import { RegionSelectComponent } from '@app/shared/components/region-select/region-select.component';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { SigninComponent } from '@app/shared/components/signin/signin.component';
import { isPlatformBrowser } from '@angular/common';
import { MenuItem } from '@app/shared/components/menu-item/menu-item.types';
import { ClientItemsService } from '@app/client/client-items.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { MenuItemComponent } from '@comp/menu-item/menu-item.component';
import { ButtonArrowComponent, ButtonComponent } from '@shared/controls';

@Component({
  selector: 'ln-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
  imports: [
    SharedModule,
    FormsModule,
    ButtonComponent,
    ButtonArrowComponent,
    SigninComponent,
    MenuItemComponent,
    SearchPanelComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuMobileComponent implements OnDestroy {
  menuItems?: MenuItem[];
  menuItems2?: MenuItem[];

  _searchOpened = false;
  _opened = false;
  isShowRegion: boolean | null = null;
  isShowSignIn: boolean | null = null;
  disableCountries = environment.disableCountries;

  @Output() hideMenuMobile = new EventEmitter();
  @Output() closeForm = new EventEmitter();

  public ArrowDirection = ArrowDirection;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  @ViewChild('regionPanel') regionPanelRef!: LazyComponentDirective;
  @ViewChild('searchPanel') searchPanelRef!: SearchPanelComponent;

  regionPanel?: ComponentRef<RegionSelectComponent>;

  @HostBinding('class.opened')
  @Input()
  set opened(value: boolean) {
    this._opened = value;
    if (!value && this.searchOpened) {
      setTimeout(() => {
        this.searchOpened = false;
      }, 300);
    }

    if (!this.regionPanel && isPlatformBrowser(this.platformId) && value) {
      setTimeout(() => {
        import('@app/shared/components/region-select/region-select.component').then((comp) => {
          this.regionPanel = this.regionPanelRef.viewContainerRef.createComponent(comp.RegionSelectComponent);
          this.regionPanel.instance.apply.pipe(takeUntil(this.destroyed$)).subscribe(() => (this.isShowRegion = false));
        });
      }, 50);
    }
  }
  get opened() {
    return this._opened;
  }

  get searchOpened() {
    return this._searchOpened;
  }
  set searchOpened(value: boolean) {
    this._searchOpened = value;
    if (this.searchPanelRef) this.searchPanelRef.showFilters = value;
  }

  constructor(
    public localize: LocalizeRouterService,
    public settings: AppSettingsService,
    private gtagService: GtagService,
    protected authService: AuthService,
    protected userService: UserService,
    private clientItemsService: ClientItemsService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,
    protected breakpoints: BreakpointsService
  ) {
    merge(this.authService.isAuthorized$, this.settings.country$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.menuItems = MENU_ITEMS(this.settings.countryCode, this.clientItemsService);
        this.menuItems2 = MENU_ITEMS2(this.settings.countryCode, this.clientItemsService);
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  hide() {
    this.hideMenuMobile.emit('');
  }

  onSearchShow() {
    if (!this.searchOpened) {
      this.gtagService.event('Search form opening', {
        'event_category': 'Profitable Engagement'
      });
    }

    this.searchOpened = true;
  }

  onBack() {
    this.searchOpened = false;
  }

  onRegion() {
    this.isShowRegion = !this.isShowRegion;
    this.settings.setCountryAccepted();
  }

  closeSwipe(isNext: boolean) {
    if ((this.isRtl() && !isNext) || (!this.isRtl() && isNext)) this.closeForm.emit();
  }

  isRtl() {
    return this.translate.instant('global.direction') === 'rtl';
  }
}
