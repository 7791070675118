import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  afterNextRender
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointsService } from '@app/shared/services';
import { filter, ReplaySubject, takeUntil } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { MenuMobileComponent } from '../header/menu-mobile/menu-mobile.component';
import { LawyerListComponent } from '@comp/lawyer';

@Component({
  selector: 'ln-menu-wrapper',
  templateUrl: './menu-wrapper.component.html',
  styleUrls: ['./menu-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule, LawyerListComponent, MenuMobileComponent, FooterComponent, HeaderComponent]
})
export class MenuWrapperComponent implements OnDestroy {
  @HostBinding('class.scroll-disabled') scrollDisabled = false;
  @HostBinding('class.chat-opened') listOpened = false;
  @HostBinding('class.menu-opened') menuMobileOpened = false;

  public destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  addMobileMenu = false;

  constructor(
    public breakpoints: BreakpointsService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    private cdr: ChangeDetectorRef
  ) {
    afterNextRender(() => {
      this.router.events
        .pipe(
          takeUntil(this.destroyed$),
          filter((event) => event instanceof NavigationEnd)
        )
        .subscribe((e) => {
          this.closeChat();
          this.closeMenu();
        });

      this.breakpoints.mobile$?.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
        if (!isMobile) {
          this.menuMobileOpened = false;
          this.listOpened = false;
          this.cdr.detectChanges();
        }
      });
    });
  }

  toggleBurger() {
    this.menuMobileOpened = !this.menuMobileOpened;
    if (this.menuMobileOpened) this.scrollDisabled = true;
    this.cdr.detectChanges();
  }
  toggleChat() {
    this.listOpened = !this.listOpened;
    if (this.listOpened) this.scrollDisabled = true;
    this.cdr.detectChanges();
  }
  closeMenu() {
    if (!this.menuMobileOpened || !isPlatformBrowser(this.platformId)) return;
    this.menuMobileOpened = false;
    this.cdr.detectChanges();
    setTimeout(() => (this.scrollDisabled = false), 600);
  }
  closeChat() {
    if (!this.listOpened || !isPlatformBrowser(this.platformId)) return;
    this.listOpened = false;
    this.cdr.detectChanges();
    setTimeout(() => (this.scrollDisabled = false), 600);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
