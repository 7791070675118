import { getSectionLink, getServicesLinkWithCountry } from '@app/shared/utils';
import { Sections } from '@app/shared/types';
import { MenuItem } from '@app/shared/components/menu-item/menu-item.types';
import { menuItemsActivity } from '@app/client/client-items.const';
import { ClientItemsService } from '@app/client/client-items.service';

export const MENU_ITEMS = (countryCode: string = '', context: ClientItemsService): MenuItem[] => [
  {
    name: 'layouts.header.menuMobile.home',
    icon: 'house',
    link: '/'
  },
  menuItemsActivity(context),
  {
    name: 'layouts.header.menuMobile.freeAdvice',
    icon: 'chat',
    link: '/ask-a-lawyer'
  },
  {
    name: 'layouts.header.menuMobile.lawyersMain',
    icon: 'users',
    items: [
      {
        name: 'layouts.header.menuMobile.lawyers',
        link: getSectionLink(Sections.Lawyers, countryCode)
      },
      {
        name: 'layouts.header.menuMobile.lawFirms',
        link: getSectionLink(Sections.LawFirms, countryCode)
      }
    ]
  },
  {
    name: 'layouts.header.menuMobile.services',
    icon: 'payment',
    link: getServicesLinkWithCountry(Sections.Services, countryCode)
  },
  {
    name: 'layouts.header.menuMobile.selfHelp',
    icon: 'stack',
    link: getSectionLink(Sections.SelfHelp, countryCode)
  }
];

export const MENU_ITEMS2 = (countryCode: string = '', context: ClientItemsService): MenuItem[] => [
  {
    name: 'layouts.header.menuMobile.forLawyers',
    icon: 'lawyer-hat',
    link: '/for-lawyers'
  },
  {
    name: 'layouts.header.menuMobile.howItWorks',
    icon: 'question',
    items: [
      {
        name: 'layouts.header.menuMobile.howItWorksClients',
        link: '/how-it-works/for-clients'
      },
      {
        name: 'layouts.header.menuMobile.howItWorksLawyers',
        link: '/how-it-works/for-lawyers'
      }
    ]
  },
  {
    name: 'layouts.header.menuMobile.more',
    icon: 'board',
    items: [
      {
        name: 'layouts.header.menuMobile.contactUs',
        link: '/contact-us'
      },
      {
        name: 'layouts.header.menuMobile.aboutUs',
        link: '/about-us'
      },
      {
        name: 'layouts.header.menuMobile.termsUse',
        link: '/terms'
      },
      {
        name: 'layouts.header.menuMobile.privacyPolicy',
        link: '/privacy-policy'
      }
    ]
  },
  context.authService.isAuthorized
    ? {
        name: 'ordersPage.menu.logout',
        icon: 'logout',
        action: () => {
          context.userService.logout();
          context.router.navigate([context.localize.translateRoute('/')]);
        }
      }
    : { name: '*' }
];
